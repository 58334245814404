import React, { Fragment, useEffect } from "react";
import plus from "../../../assets/plus.png";
import location from "../../../assets/location.jpg";
import minus from "../../../assets/minus.png";
import Moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { supplierActions } from "../../../actions";
import { categoryActions } from "../../../actions";
import { getLocalStorage } from "../../../utils/helper/localStorage";
import { LOCAL_STORAGE_USER } from "../../../../constants";
import { useForm } from "react-hook-form";
import { supplierConstants } from "../../../constants";
import { Dialog, Tooltip } from "@mui/material";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import classNames from "classnames";
import ReactDOM from "react-dom";
import $ from "jquery";

export default function SupplierRegistration({
  open,
  onClose,
  onSubmit,
  openLocation,
  latitude,
  longtitude,
  address,
  postalCode,
  onInputAddress,
  onPinLocation,
}) {
  const [isError, setIsError] = React.useState(true);
  const [requiredValue, setRequiredValue] = React.useState([]);
  const dispatch = useDispatch();
  const current_date = Moment(new Date()).format("YYYY/MM/DD");
  const userLogin = getLocalStorage(LOCAL_STORAGE_USER);
  const create = useSelector((state) => state.supplier.create);
  const bigCategory = useSelector((state) => state.category.getBigCategory);
  const medCategory = useSelector((state) => state.category.getMediumCategory);
  const detailCategory = useSelector(
    (state) => state.category.getDetailCategory
  );
  const [isAllTime, setIsAllTime] = React.useState(false);
  const [catLevel, setCatLevel] = React.useState([
    { level1: "", level2: "", level3: "" },
  ]);
  const [categoryLevel1, setCategoryLevel1] = React.useState([]);
  const [categoryLevel2, setCategoryLevel2] = React.useState([]);
  const [categoryLevel3, setCategoryLevel3] = React.useState([]);

  const [errorTime1, setErrorTime1] = React.useState(false);
  const [errorTime2, setErrorTime2] = React.useState(false);
  const [errorTime3, setErrorTime3] = React.useState(false);
  const API_KEY = import.meta.env.VITE_GOOGLE_MAP_API_KEY;

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setError,
    setValue,
    reset,
    getValues,
    trigger,
  } = useForm();

  React.useEffect(() => {
    if (open) {
      initData();
      setIsAllTime(false);
    }
  }, [open]);

  React.useEffect(() => {
    if (address || postalCode) {
      setValue("address_detail", address);
      setValue("address_postalcode", postalCode);
      setRequiredValue({
        ...requiredValue,
        [`address_detail`]: true,
        [`address_postalcode`]: true,
      });
    }
  }, [address, postalCode]);

  React.useEffect(() => {
    if (bigCategory.items) {
      let categoryLevel1 = bigCategory.items.map((cat) => {
        return {
          value: cat.id,
          label: cat.name,
        };
      });
      categoryLevel1.unshift({ value: "", label: "大カテゴリ" });
      setCategoryLevel1(categoryLevel1);
    } else {
      setCategoryLevel1([{ value: "", label: "大カテゴリ" }]);
    }
    if (medCategory.items) {
      let categoryLevel2 = medCategory.items.map((cat) => {
        return {
          value: cat.id,
          label: cat.name,
          level1: cat.big_id,
        };
      });
      categoryLevel2.unshift({ value: "", label: "中カテゴリ" });
      setCategoryLevel2(categoryLevel2);
    } else {
      setCategoryLevel2([{ value: "", label: "中カテゴリ" }]);
    }
    if (detailCategory.items) {
      let categoryLevel3 = detailCategory.items.map((cat) => {
        return {
          value: cat.id,
          label: cat.name,
          level1: cat.big_id,
          level2: cat.medium_id,
        };
      });
      categoryLevel3.unshift({ value: "", label: "詳細" });
      setCategoryLevel3(categoryLevel3);
    } else {
      setCategoryLevel3([{ value: "", label: "詳細" }]);
    }
  }, [bigCategory, medCategory, detailCategory]);

  const initData = async (data) => {
    await dispatch(categoryActions.getBigCategory());
    await dispatch(categoryActions.getMediumCategory());
    await dispatch(categoryActions.getDetailCategory());
  };

  const handleFormSubmit = async (data) => {
    if (catLevel.length != 1 || catLevel[0].level1 != "") {
      data.cat = catLevel;
    }
    if (!data.business_start_hour) {
      delete data.business_start_hour;
    }
    if (!data.business_end_hour) {
      delete data.business_end_hour;
    }
    if (!data.rest_start_hour) {
      delete data.rest_start_hour;
    }
    if (!data.rest_end_hour) {
      delete data.rest_end_hour;
    }
    if (!data.rest_start_hour_2) {
      delete data.rest_start_hour_2;
    }
    if (!data.rest_end_hour_2) {
      delete data.rest_end_hour_2;
    }
    if (isAllTime) {
      data.all_day_business_possible = 1;
    } else {
      data.all_day_business_possible = 0;
      data.rest_start_hour_2 = null;
      data.rest_end_hour_2 = null;
    }

    data.admin_id = userLogin?.id;
    data.detail = data.detail ?? "";
    data.latitude = latitude;
    data.longtitude = longtitude;
    data.pin_color = 1;

    await dispatch(supplierActions.create(data));
  };

  const handleChangeRequiredValue = (e) => {
    if (e.target?.value) {
      setRequiredValue({
        ...requiredValue,
        [`${e.target.name}`]: true,
      });
    } else {
      setRequiredValue({
        ...requiredValue,
        [`${e.target.name}`]: false,
      });
    }
  };

  React.useEffect(() => {
    if (
      !requiredValue["name"] ||
      !requiredValue["address_postalcode"] ||
      !requiredValue["address_detail"]
    ) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  }, [requiredValue]);

  React.useEffect(() => {
    if (create.item) {
      dispatch({ type: supplierConstants.CREATE_SUCCESS, payload: null });
      reset();
      resetCatLevel();
      setRequiredValue([]);
      onSubmit();
    } else if (create.error) {
      if (create.error.email) {
        const formError = {
          type: "server",
          message: create.error.email.message,
        };
        setError("email", formError);
      }
    }
  }, [create]);

  const handleChangeCat = (e, index, level) => {
    const newCatLevel = catLevel.map((c, i) => {
      if (i === index) {
        if (level == 1) {
          return {
            ...c,
            level1: e.target.value,
            level2: "",
            level3: "",
          };
        } else if (level == 2) {
          return {
            ...c,
            level2: e.target.value,
            level3: "",
          };
        } else {
          return {
            ...c,
            level3: e.target.value,
          };
        }
      } else {
        return c;
      }
    });
    setCatLevel(newCatLevel);
    $(function () {
      $("#bigCategory").each(function () {
        this.style.setProperty("color", "#000", "important");
      });
      $($("[id=bigCategory]")).each(function () {
        if ($(this).val() == "") $(this).css({ color: "#aaa" });
        else $(this).css({ color: "#000" });
      });
    });
  };

  const handleAddCategory = () => {
    setCatLevel([...catLevel, { level1: "", level2: "", level3: "" }]);
  };

  const handledeleteCategory = (index) => {
    var newCat = catLevel.filter((ele, ind) => ind !== index);
    setCatLevel(newCat);
  };

  const times = [
    { value: "", label: "未設定" },
    { value: "00:00:00", label: "00:00" },
    { value: "01:00:00", label: "01:00" },
    { value: "02:00:00", label: "02:00" },
    { value: "03:00:00", label: "03:00" },
    { value: "04:00:00", label: "04:00" },
    { value: "05:00:00", label: "05:00" },
    { value: "06:00:00", label: "06:00" },
    { value: "07:00:00", label: "07:00" },
    { value: "08:00:00", label: "08:00" },
    { value: "09:00:00", label: "09:00" },
    { value: "10:00:00", label: "10:00" },
    { value: "11:00:00", label: "11:00" },
    { value: "12:00:00", label: "12:00" },
    { value: "13:00:00", label: "13:00" },
    { value: "14:00:00", label: "14:00" },
    { value: "15:00:00", label: "15:00" },
    { value: "16:00:00", label: "16:00" },
    { value: "17:00:00", label: "17:00" },
    { value: "18:00:00", label: "18:00" },
    { value: "19:00:00", label: "19:00" },
    { value: "20:00:00", label: "20:00" },
    { value: "21:00:00", label: "21:00" },
    { value: "22:00:00", label: "22:00" },
    { value: "23:00:00", label: "23:00" },
  ];

  const resetCatLevel = () => {
    setCatLevel([{ level1: "", level2: "", level3: "" }]);
    setValue("business_start_hour", undefined);
    setValue("business_end_hour", undefined);
    setValue("rest_start_hour", undefined);
    setValue("rest_end_hour", undefined);
    setValue("rest_start_hour_2", undefined);
    setValue("rest_end_hour_2", undefined);
  };

  const resetErrorState = () => {
    setErrorTime1(false);
    setErrorTime2(false);
    setErrorTime3(false);
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick" && "escapeKeyDown") return;
    onClose(false);
    resetCatLevel();
    reset();
    resetErrorState();
    setRequiredValue([]);
    dispatch({ type: supplierConstants.CREATE_SUCCESS, payload: null });
  };

  $(function () {
    $("select").on("change", function () {
      if ($(this).val() == "") $(this).css({ color: "#aaa" });
      else $(this).css({ color: "#000" });
      $($("[id=thirdCategory]")).each(function () {
        if ($(this).val() != "") $(this).css({ color: "#aaa" });
      });
    });
    $("#bigCategory").on("change", function () {
      $("#bigCategory").each(function () {
        this.style.setProperty("color", "#000", "important");
      });
    });
    $($("[id=thirdCategory]")).each(function () {
      if ($(this).val() != "") $(this).css({ color: "#000" });
    });
  });

  const handleClickAway = () => {
    $(function () {
      if ($("button:contains('場所を選択')").length == 0) {
        handleClose();
        // reset();
      }
    });
  };

  const handleChangeTime = (e) => {
    setValue(`${e.target.name}`, e.target?.value);
    if (
      getValues("business_start_hour") != "" &&
      getValues("business_end_hour") != "" &&
      getValues("business_start_hour") >= getValues("business_end_hour")
    ) {
      setErrorTime1(true);
    } else {
      setErrorTime1(false);
    }

    if (
      getValues("rest_start_hour") != "" &&
      getValues("rest_end_hour") != "" &&
      getValues("rest_start_hour") >= getValues("rest_end_hour")
    ) {
      setErrorTime2(true);
    } else {
      setErrorTime2(false);
    }

    if (
      getValues("rest_start_hour_2") != "" &&
      getValues("rest_end_hour_2") != "" &&
      getValues("rest_start_hour_2") >= getValues("rest_end_hour_2")
    ) {
      setErrorTime3(true);
    } else {
      setErrorTime3(false);
    }
  };

  const handleInputPostalCode = (event) => {
    const postalCode = event.target.value;
    if (postalCode) {
      fetch(
        "https://maps.googleapis.com/maps/api/geocode/json?address=" +
          postalCode +
          "&key=" +
          API_KEY +
          "&language=ja"
      )
        .then((response) => response.json())
        .then((data) => {
          if (data?.results?.length > 0) {
            const addressDetail =
              // data?.results[0]?.formatted_address +
              data?.results[0]?.address_components[3]?.long_name +
              data?.results[0]?.address_components[2]?.long_name +
              data?.results[0]?.address_components[1]?.long_name;
            setValue("address_detail", addressDetail);
            onInputAddress(
              data?.results[0]?.geometry?.location?.lat,
              data?.results[0]?.geometry?.location?.lng,
              addressDetail,
              postalCode
            );
            onPinLocation(
              data?.results[0]?.geometry?.location?.lat,
              data?.results[0]?.geometry?.location?.lng
            );
          } else {
            onPinLocation(35.6812405, 139.7646955);
          }
        });
    } else {
      setValue("address_detail", "");
    }
  };

  return (
    <div className="w-fit h-fit">
      <Dialog open={open} onClose={handleClose} className="z-40">
        <form>
          <div className="justify-center items-center flex fixed inset-0 z-40 outline-none focus:outline-none text-sm font-medium">
            <ClickAwayListener onClickAway={handleClickAway}>
              <div className="w-fit h-[85%] bg-white rounded-[10px] flex flex-col pb-[10px] overflow-y-auto pr-[20px]">
                <div className=" ml-[35px]  mt-[22px] text-black text-xl font-bold ">
                  仕入先新規登録
                </div>
                <div className="flex flex-row  ml-[36px] mt-[30px] items-center">
                  <div className="w-[140px]">仕入先名</div>
                  <input
                    {...register("name", {
                      onChange: (e) => handleChangeRequiredValue(e),
                    })}
                    className="p-2 w-[670px] h-[35px] bg-white rounded-[10px] border border-stone-300 text-sm"
                    autoComplete="off"
                    id=""
                    type="text"
                    placeholder="横浜ライト工業株式会社"
                    onFocus={(e) => (e.target.placeholder = "")}
                    onBlur={(e) =>
                      (e.target.placeholder = "横浜ライト工業株式会社")
                    }
                  />
                </div>
                <div className="w-[825px] h-px bg-zinc-300 ml-[28px] mt-[8px] border-b border-gray-200" />
                <div className="flex flex-row ml-[36px] mt-[10px] items-center">
                  <div className="w-[140px]">所在地</div>
                  <input
                    {...register("address_postalcode", {
                      onChange: (e) => handleChangeRequiredValue(e),
                    })}
                    onChange={handleInputPostalCode}
                    className="p-2 w-[291px] h-[35px] bg-white rounded-[10px] border border-stone-300 text-sm"
                    placeholder="〒240-0035"
                    onFocus={(e) => (e.target.placeholder = "")}
                    onBlur={(e) => (e.target.placeholder = "〒240-0035")}
                    autoComplete="off"
                  />
                  <img
                    onClick={() => openLocation()}
                    className="w-[30px] h-[30px] ml-[330px] cursor-pointer"
                    src={location}
                  />
                </div>
                <div className="flex flex-row ml-[176px] mt-[17px]">
                  <div className="flex flex-col">
                    <input
                      {...register("address_detail", {
                        onChange: (e) => handleChangeRequiredValue(e),
                      })}
                      className="p-2 w-[666px] h-[35px] bg-white rounded-[10px] border border-stone-300 text-sm"
                      placeholder="神奈川県横浜市保土ヶ谷区今井町870"
                      onFocus={(e) => (e.target.placeholder = "")}
                      onBlur={(e) =>
                        (e.target.placeholder =
                          "神奈川県横浜市保土ヶ谷区今井町870")
                      }
                    />
                  </div>
                </div>
                <div className="w-[825px] h-px bg-zinc-300 ml-[28px] mt-[8px] border-b border-gray-200" />
                <div className="flex flex-row ml-[36px] mt-[8px] items-center">
                  <div className="w-[140px]">企業電話番号</div>
                  <div className="flex flex-col">
                    <input
                      {...register("company_phone_number", {
                        pattern: {
                          value: /^[0-9０-９]*$/,
                          message:
                            "有効な企業電話番号（英数字のみ）を入力してください",
                        },
                      })}
                      className="p-2 w-[666px] h-[35px] bg-white rounded-[10px] border border-stone-300"
                      placeholder="03-1234-5678"
                      onFocus={(e) => (e.target.placeholder = "")}
                      onBlur={(e) => (e.target.placeholder = "03-1234-5678")}
                    />
                  </div>
                </div>
                {errors.company_phone_number && (
                  <div className="ml-[185px] w-fit text-red-500 mb-[15px] h-px">
                    <span>{errors.company_phone_number.message}</span>
                  </div>
                )}
                <div className="w-[825px] h-px bg-zinc-300 ml-[28px] mt-[8px] border-b border-gray-200" />
                <div className="flex flex-row ml-[36px] mt-[8px] items-center">
                  <div className="w-[140px]">企業FAX番号</div>
                  <input
                    {...register("company_fax")}
                    className="p-2 w-[666px] h-[34px] bg-white rounded-[10px] border border-stone-300 text-sm"
                    placeholder="080.48924"
                    onFocus={(e) => (e.target.placeholder = "")}
                    onBlur={(e) => (e.target.placeholder = "080.48924")}
                  />
                </div>
                <div className="w-[825px] h-px bg-zinc-300 ml-[28px] mt-[8px] border-b border-gray-200" />
                <div className="flex flex-row ml-[36px] mt-[8px] items-center">
                  <div className="w-[140px]">営業時間</div>
                  <div className="flex flex-row items-center">
                    {/* <select
                      name="business_start_hour"
                      {...register("business_start_hour", {
                        onChange: (e) => handleChangeTime(e),
                      })}
                      className={classNames(
                        "p-1 w-40 h-8 bg-white rounded-[10px] border border-stone-300"
                      )}
                    >
                      {times.map((item, i) => {
                        return (
                          <option
                            key={item.value + "_" + i}
                            className="text-gray-900 dark:text-gray-300"
                            value={item.value}
                          >
                            {item.label}
                          </option>
                        );
                      })}
                    </select> */}
                    <input
                      className="p-2 w-40 h-8 bg-white rounded-[10px] border border-stone-300"
                      list="business_start_hour"
                      type="text"
                      maxLength={5}
                      autoComplete="off"
                      role="combobox"
                      name="business_start_hour"
                      {...register(
                        "business_start_hour",
                        {
                          maxLength: 5,
                          minLength: 5,
                          pattern: /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/i,
                        },
                        {
                          onChange: (e) => handleChangeTime(e),
                        }
                      )}
                      onBlur={async () => {
                        await trigger("business_start_hour");
                      }}
                      onChange={(e) => handleChangeTime(e)}
                      placeholder="未設定"
                    />
                    <datalist
                      id="business_start_hour"
                      className="absolute bg-white overflow-y-auto h-[300px]"
                    >
                      <option value="00:00"></option>
                      <option value="00:30"></option>
                      <option value="01:00"></option>
                      <option value="01:30"></option>
                      <option value="02:00"></option>
                      <option value="02:30"></option>
                      <option value="03:00"></option>
                      <option value="03:30"></option>
                      <option value="04:00"></option>
                      <option value="04:30"></option>
                      <option value="05:00"></option>
                      <option value="05:30"></option>
                      <option value="06:00"></option>
                      <option value="06:30"></option>
                      <option value="07:00"></option>
                      <option value="07:30"></option>
                      <option value="08:00"></option>
                      <option value="08:30"></option>
                      <option value="09:00"></option>
                      <option value="09:30"></option>
                      <option value="10:00"></option>
                      <option value="10:30"></option>
                      <option value="11:00"></option>
                      <option value="11:30"></option>
                      <option value="12:00"></option>
                      <option value="12:30"></option>
                      <option value="13:00"></option>
                      <option value="13:30"></option>
                      <option value="14:00"></option>
                      <option value="14:30"></option>
                      <option value="15:00"></option>
                      <option value="15:30"></option>
                      <option value="16:00"></option>
                      <option value="16:30"></option>
                      <option value="17:00"></option>
                      <option value="17:30"></option>
                      <option value="18:00"></option>
                      <option value="18:30"></option>
                      <option value="19:00"></option>
                      <option value="19:30"></option>
                      <option value="20:00"></option>
                      <option value="20:30"></option>
                      <option value="21:00"></option>
                      <option value="21:30"></option>
                      <option value="22:00"></option>
                      <option value="22:30"></option>
                      <option value="23:00"></option>
                      <option value="23:30"></option>
                    </datalist>
                    <div className="text-black text-sm  ml-[13px] font-normal ">
                      〜
                    </div>
                    {/* <select
                      name="business_end_hour"
                      {...register("business_end_hour", {
                        onChange: (e) => handleChangeTime(e),
                      })}
                      className="p-1 w-40 h-8 bg-white rounded-[10px] ml-[13px] border border-stone-300"
                    >
                      {times.map((item, i) => {
                        return (
                          <option
                            key={item.value + "_" + i}
                            className="text-gray-900 dark:text-gray-300"
                            value={item.value}
                          >
                            {item.label}
                          </option>
                        );
                      })}
                    </select> */}
                    <input
                      className="p-2 w-40 h-8 bg-white rounded-[10px] border border-stone-300 ml-[10px]"
                      list="business_end_hour"
                      type="text"
                      maxLength={5}
                      autoComplete="off"
                      role="combobox"
                      name="business_end_hour"
                      {...register(
                        "business_end_hour",
                        {
                          maxLength: 5,
                          minLength: 5,
                          pattern: /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/i,
                        },
                        {
                          onChange: (e) => handleChangeTime(e),
                        }
                      )}
                      onBlur={async () => {
                        await trigger("business_end_hour");
                      }}
                      onChange={(e) => handleChangeTime(e)}
                      placeholder="未設定"
                    />
                    <datalist
                      id="business_end_hour"
                      className="absolute bg-white overflow-y-auto h-[300px]"
                    >
                      <option value="00:00"></option>
                      <option value="00:30"></option>
                      <option value="01:00"></option>
                      <option value="01:30"></option>
                      <option value="02:00"></option>
                      <option value="02:30"></option>
                      <option value="03:00"></option>
                      <option value="03:30"></option>
                      <option value="04:00"></option>
                      <option value="04:30"></option>
                      <option value="05:00"></option>
                      <option value="05:30"></option>
                      <option value="06:00"></option>
                      <option value="06:30"></option>
                      <option value="07:00"></option>
                      <option value="07:30"></option>
                      <option value="08:00"></option>
                      <option value="08:30"></option>
                      <option value="09:00"></option>
                      <option value="09:30"></option>
                      <option value="10:00"></option>
                      <option value="10:30"></option>
                      <option value="11:00"></option>
                      <option value="11:30"></option>
                      <option value="12:00"></option>
                      <option value="12:30"></option>
                      <option value="13:00"></option>
                      <option value="13:30"></option>
                      <option value="14:00"></option>
                      <option value="14:30"></option>
                      <option value="15:00"></option>
                      <option value="15:30"></option>
                      <option value="16:00"></option>
                      <option value="16:30"></option>
                      <option value="17:00"></option>
                      <option value="17:30"></option>
                      <option value="18:00"></option>
                      <option value="18:30"></option>
                      <option value="19:00"></option>
                      <option value="19:30"></option>
                      <option value="20:00"></option>
                      <option value="20:30"></option>
                      <option value="21:00"></option>
                      <option value="21:30"></option>
                      <option value="22:00"></option>
                      <option value="22:30"></option>
                      <option value="23:00"></option>
                      <option value="23:30"></option>
                    </datalist>
                    <input
                      type="checkbox"
                      className="p-2 w-3.5 h-3.5 bg-blue-900 rounded-sm ml-[20px]"
                      onChange={() => setIsAllTime(!isAllTime)}
                    />
                    <div className="text-zinc-800 text-sm font-medium ml-[4px] leading-[18px]">
                      24時間営業
                    </div>
                  </div>
                </div>
                {(errors?.business_start_hour?.type === "pattern" ||
                  errors?.business_end_hour?.type === "pattern") && (
                  <p
                    className="ml-[185px] w-fit text-red-500 mb-[15px] h-px"
                    role="alert"
                  >
                    無効な時間です。有効な時間の例: 10:10
                  </p>
                )}
                {(errors?.business_start_hour?.type === "minLength" ||
                  errors?.business_end_hour?.type === "minLength") && (
                  <p
                    className="ml-[185px] w-fit text-red-500 mb-[15px] h-px"
                    role="alert"
                  >
                    無効な時間です。有効な時間の例: 10:10
                  </p>
                )}
                {errorTime1 && (
                  <div className="ml-[185px] w-fit text-red-500 mb-[15px] h-px">
                    <span>終了時間を開始時間より設定してください</span>
                  </div>
                )}
                <div className="flex flex-row ml-[36px] mt-[8px] items-center">
                  <div className="w-[140px]">休憩時間（任意）</div>
                  <div className="flex flex-rowitems-center">
                    {/* <select
                      name="rest_start_hour"
                      {...register("rest_start_hour", {
                        onChange: (e) => handleChangeTime(e),
                      })}
                      className="p-1 w-40 h-8 bg-white rounded-[10px] border border-stone-300"
                    >
                      {times.map((item, i) => {
                        return (
                          <option
                            key={item.value + "_" + i}
                            className="text-gray-900 dark:text-gray-300"
                            value={item.value}
                          >
                            {item.label}
                          </option>
                        );
                      })}
                    </select> */}
                    <input
                      className="p-2 w-40 h-8 bg-white rounded-[10px] border border-stone-300"
                      list="rest_start_hour"
                      type="text"
                      maxLength={5}
                      autoComplete="off"
                      role="combobox"
                      name="rest_start_hour"
                      {...register(
                        "rest_start_hour",
                        {
                          maxLength: 5,
                          minLength: 5,
                          pattern: /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/i,
                        },
                        {
                          onChange: (e) => handleChangeTime(e),
                        }
                      )}
                      onBlur={async () => {
                        await trigger("rest_start_hour");
                      }}
                      onChange={(e) => handleChangeTime(e)}
                      placeholder="未設定"
                    />
                    <datalist
                      id="rest_start_hour"
                      className="absolute bg-white overflow-y-auto h-[300px]"
                    >
                      <option value="00:00"></option>
                      <option value="00:30"></option>
                      <option value="01:00"></option>
                      <option value="01:30"></option>
                      <option value="02:00"></option>
                      <option value="02:30"></option>
                      <option value="03:00"></option>
                      <option value="03:30"></option>
                      <option value="04:00"></option>
                      <option value="04:30"></option>
                      <option value="05:00"></option>
                      <option value="05:30"></option>
                      <option value="06:00"></option>
                      <option value="06:30"></option>
                      <option value="07:00"></option>
                      <option value="07:30"></option>
                      <option value="08:00"></option>
                      <option value="08:30"></option>
                      <option value="09:00"></option>
                      <option value="09:30"></option>
                      <option value="10:00"></option>
                      <option value="10:30"></option>
                      <option value="11:00"></option>
                      <option value="11:30"></option>
                      <option value="12:00"></option>
                      <option value="12:30"></option>
                      <option value="13:00"></option>
                      <option value="13:30"></option>
                      <option value="14:00"></option>
                      <option value="14:30"></option>
                      <option value="15:00"></option>
                      <option value="15:30"></option>
                      <option value="16:00"></option>
                      <option value="16:30"></option>
                      <option value="17:00"></option>
                      <option value="17:30"></option>
                      <option value="18:00"></option>
                      <option value="18:30"></option>
                      <option value="19:00"></option>
                      <option value="19:30"></option>
                      <option value="20:00"></option>
                      <option value="20:30"></option>
                      <option value="21:00"></option>
                      <option value="21:30"></option>
                      <option value="22:00"></option>
                      <option value="22:30"></option>
                      <option value="23:00"></option>
                      <option value="23:30"></option>
                    </datalist>
                    <div className="text-black text-sm  ml-[13px] font-normal ">
                      〜
                    </div>
                    {/* <select
                      name="rest_end_hour"
                      {...register("rest_end_hour", {
                        onChange: (e) => handleChangeTime(e),
                      })}
                      className="p-1 w-40 h-8 bg-white rounded-[10px] ml-[13px] border border-stone-300"
                    >
                      {times.map((item, i) => {
                        return (
                          <option
                            key={item.value + "_" + i}
                            className="text-gray-900 dark:text-gray-300"
                            value={item.value}
                          >
                            {item.label}
                          </option>
                        );
                      })}
                    </select> */}
                    <input
                      className="p-2 w-40 h-8 bg-white rounded-[10px] border border-stone-300 ml-[10px]"
                      list="rest_end_hour"
                      type="text"
                      maxLength={5}
                      autoComplete="off"
                      role="combobox"
                      name="rest_end_hour"
                      {...register(
                        "rest_end_hour",
                        {
                          maxLength: 5,
                          minLength: 5,
                          pattern: /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/i,
                        },
                        {
                          onChange: (e) => handleChangeTime(e),
                        }
                      )}
                      onBlur={async () => {
                        await trigger("rest_end_hour");
                      }}
                      onChange={(e) => handleChangeTime(e)}
                      placeholder="未設定"
                    />
                    <datalist
                      id="rest_end_hour"
                      className="absolute bg-white overflow-y-auto h-[300px]"
                    >
                      <option value="00:00"></option>
                      <option value="00:30"></option>
                      <option value="01:00"></option>
                      <option value="01:30"></option>
                      <option value="02:00"></option>
                      <option value="02:30"></option>
                      <option value="03:00"></option>
                      <option value="03:30"></option>
                      <option value="04:00"></option>
                      <option value="04:30"></option>
                      <option value="05:00"></option>
                      <option value="05:30"></option>
                      <option value="06:00"></option>
                      <option value="06:30"></option>
                      <option value="07:00"></option>
                      <option value="07:30"></option>
                      <option value="08:00"></option>
                      <option value="08:30"></option>
                      <option value="09:00"></option>
                      <option value="09:30"></option>
                      <option value="10:00"></option>
                      <option value="10:30"></option>
                      <option value="11:00"></option>
                      <option value="11:30"></option>
                      <option value="12:00"></option>
                      <option value="12:30"></option>
                      <option value="13:00"></option>
                      <option value="13:30"></option>
                      <option value="14:00"></option>
                      <option value="14:30"></option>
                      <option value="15:00"></option>
                      <option value="15:30"></option>
                      <option value="16:00"></option>
                      <option value="16:30"></option>
                      <option value="17:00"></option>
                      <option value="17:30"></option>
                      <option value="18:00"></option>
                      <option value="18:30"></option>
                      <option value="19:00"></option>
                      <option value="19:30"></option>
                      <option value="20:00"></option>
                      <option value="20:30"></option>
                      <option value="21:00"></option>
                      <option value="21:30"></option>
                      <option value="22:00"></option>
                      <option value="22:30"></option>
                      <option value="23:00"></option>
                      <option value="23:30"></option>
                    </datalist>
                  </div>
                </div>
                {(errors?.rest_start_hour?.type === "pattern" ||
                  errors?.rest_end_hour?.type === "pattern") && (
                  <p
                    className="ml-[185px] w-fit text-red-500 mb-[15px] h-px"
                    role="alert"
                  >
                    無効な時間です。有効な時間の例: 10:10
                  </p>
                )}
                {(errors?.rest_start_hour?.type === "minLength" ||
                  errors?.rest_end_hour?.type === "minLength") && (
                  <p
                    className="ml-[185px] w-fit text-red-500 mb-[15px] h-px"
                    role="alert"
                  >
                    無効な時間です。有効な時間の例: 10:10
                  </p>
                )}
                {errorTime2 && (
                  <div className="ml-[185px] w-fit text-red-500 mb-[15px] h-px">
                    <span>終了時間を開始時間より設定してください</span>
                  </div>
                )}
                {isAllTime && (
                  <>
                    <div className="flex flex-row ml-[36px] mt-[8px] items-center">
                      <div className="w-[140px]">休憩時間２（任意）</div>
                      <div className="flex flex-row items-center">
                        {/* <select
                          name="rest_start_hour_2"
                          {...register("rest_start_hour_2", {
                            onChange: (e) => handleChangeTime(e),
                          })}
                          className="p-1 w-40 h-8 bg-white rounded-[10px] border border-stone-300"
                        >
                          {times.map((item, i) => {
                            return (
                              <option
                                key={item.value + "_" + i}
                                className="text-gray-900 dark:text-gray-300"
                                value={item.value}
                              >
                                {item.label}
                              </option>
                            );
                          })}
                        </select> */}
                        <input
                          className="p-1 w-40 h-8 bg-white rounded-[10px] border border-stone-300"
                          list="rest_start_hour_2"
                          type="text"
                          maxLength={5}
                          autoComplete="off"
                          role="combobox"
                          name="rest_start_hour_2"
                          {...register(
                            "rest_start_hour_2",
                            {
                              maxLength: 5,
                              minLength: 5,
                              pattern: /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/i,
                            },
                            {
                              onChange: (e) => handleChangeTime(e),
                            }
                          )}
                          onBlur={async () => {
                            await trigger("rest_start_hour_2");
                          }}
                          onChange={(e) => handleChangeTime(e)}
                          placeholder="未設定"
                        />
                        <datalist
                          id="rest_start_hour_2"
                          className="absolute bg-white overflow-y-auto h-[300px]"
                        >
                          <option value="00:00"></option>
                          <option value="00:30"></option>
                          <option value="01:00"></option>
                          <option value="01:30"></option>
                          <option value="02:00"></option>
                          <option value="02:30"></option>
                          <option value="03:00"></option>
                          <option value="03:30"></option>
                          <option value="04:00"></option>
                          <option value="04:30"></option>
                          <option value="05:00"></option>
                          <option value="05:30"></option>
                          <option value="06:00"></option>
                          <option value="06:30"></option>
                          <option value="07:00"></option>
                          <option value="07:30"></option>
                          <option value="08:00"></option>
                          <option value="08:30"></option>
                          <option value="09:00"></option>
                          <option value="09:30"></option>
                          <option value="10:00"></option>
                          <option value="10:30"></option>
                          <option value="11:00"></option>
                          <option value="11:30"></option>
                          <option value="12:00"></option>
                          <option value="12:30"></option>
                          <option value="13:00"></option>
                          <option value="13:30"></option>
                          <option value="14:00"></option>
                          <option value="14:30"></option>
                          <option value="15:00"></option>
                          <option value="15:30"></option>
                          <option value="16:00"></option>
                          <option value="16:30"></option>
                          <option value="17:00"></option>
                          <option value="17:30"></option>
                          <option value="18:00"></option>
                          <option value="18:30"></option>
                          <option value="19:00"></option>
                          <option value="19:30"></option>
                          <option value="20:00"></option>
                          <option value="20:30"></option>
                          <option value="21:00"></option>
                          <option value="21:30"></option>
                          <option value="22:00"></option>
                          <option value="22:30"></option>
                          <option value="23:00"></option>
                          <option value="23:30"></option>
                        </datalist>
                        <div className="text-black text-sm  ml-[13px] font-normal ">
                          〜
                        </div>
                        {/* <select
                          name="rest_end_hour_2"
                          {...register("rest_end_hour_2", {
                            onChange: (e) => handleChangeTime(e),
                          })}
                          className="p-1 w-40 h-8 bg-white rounded-[10px] ml-[13px] border border-stone-300"
                        >
                          {times.map((item, i) => {
                            return (
                              <option
                                key={item.value + "_" + i}
                                className="text-gray-900 dark:text-gray-300"
                                value={item.value}
                              >
                                {item.label}
                              </option>
                            );
                          })}
                        </select> */}
                        <input
                          className="p-1 w-40 h-8 bg-white rounded-[10px] border border-stone-300 ml-[10px]"
                          list="rest_end_hour_2"
                          type="text"
                          maxLength={5}
                          autoComplete="off"
                          role="combobox"
                          name="rest_end_hour_2"
                          {...register(
                            "rest_end_hour_2",
                            {
                              maxLength: 5,
                              minLength: 5,
                              pattern: /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/i,
                            },
                            {
                              onChange: (e) => handleChangeTime(e),
                            }
                          )}
                          onBlur={async () => {
                            await trigger("rest_end_hour_2");
                          }}
                          onChange={(e) => handleChangeTime(e)}
                          placeholder="未設定"
                        />
                        <datalist
                          id="rest_end_hour_2"
                          className="absolute bg-white overflow-y-auto h-[300px]"
                        >
                          <option value="00:00"></option>
                          <option value="00:30"></option>
                          <option value="01:00"></option>
                          <option value="01:30"></option>
                          <option value="02:00"></option>
                          <option value="02:30"></option>
                          <option value="03:00"></option>
                          <option value="03:30"></option>
                          <option value="04:00"></option>
                          <option value="04:30"></option>
                          <option value="05:00"></option>
                          <option value="05:30"></option>
                          <option value="06:00"></option>
                          <option value="06:30"></option>
                          <option value="07:00"></option>
                          <option value="07:30"></option>
                          <option value="08:00"></option>
                          <option value="08:30"></option>
                          <option value="09:00"></option>
                          <option value="09:30"></option>
                          <option value="10:00"></option>
                          <option value="10:30"></option>
                          <option value="11:00"></option>
                          <option value="11:30"></option>
                          <option value="12:00"></option>
                          <option value="12:30"></option>
                          <option value="13:00"></option>
                          <option value="13:30"></option>
                          <option value="14:00"></option>
                          <option value="14:30"></option>
                          <option value="15:00"></option>
                          <option value="15:30"></option>
                          <option value="16:00"></option>
                          <option value="16:30"></option>
                          <option value="17:00"></option>
                          <option value="17:30"></option>
                          <option value="18:00"></option>
                          <option value="18:30"></option>
                          <option value="19:00"></option>
                          <option value="19:30"></option>
                          <option value="20:00"></option>
                          <option value="20:30"></option>
                          <option value="21:00"></option>
                          <option value="21:30"></option>
                          <option value="22:00"></option>
                          <option value="22:30"></option>
                          <option value="23:00"></option>
                          <option value="23:30"></option>
                        </datalist>
                      </div>
                    </div>
                    {(errors?.rest_start_hour_2?.type === "pattern" ||
                      errors?.rest_end_hour_2?.type === "pattern") && (
                      <p
                        className="ml-[185px] w-fit text-red-500 mb-[15px] h-px"
                        role="alert"
                      >
                        無効な時間です。有効な時間の例: 10:10
                      </p>
                    )}
                    {(errors?.rest_start_hour_2?.type === "minLength" ||
                      errors?.rest_end_hour_2?.type === "minLength") && (
                      <p
                        className="ml-[185px] w-fit text-red-500 mb-[15px] h-px"
                        role="alert"
                      >
                        無効な時間です。有効な時間の例: 10:10
                      </p>
                    )}
                    {errorTime3 && (
                      <div className="ml-[185px] w-fit text-red-500 mb-[15px] h-px">
                        <span>終了時間を開始時間より設定してください</span>
                      </div>
                    )}
                  </>
                )}
                <div className="w-[825px] h-px bg-zinc-300 ml-[28px] mt-[8px] border-b border-gray-200" />
                <div className="flex flex-row ml-[36px] mt-[8px] items-end">
                  <div className="w-[140px]">定休日</div>
                  <input
                    defaultValue="1"
                    name="is_regular_day_off_on_monday"
                    {...register("is_regular_day_off_on_monday")}
                    type="checkbox"
                    className="w-3.5 h-3.5 bg-blue-900 rounded-sm mt-[8px]"
                  />
                  <div className="text-zinc-800 text-[11px] font-medium ml-[3px] mt-[6px] leading-[18px]">
                    月
                  </div>
                  <input
                    defaultValue="1"
                    name="is_regular_day_off_on_tuesday"
                    {...register("is_regular_day_off_on_tuesday")}
                    type="checkbox"
                    className="w-3.5 h-3.5 bg-blue-900 rounded-sm ml-[13px] mt-[8px]"
                  />
                  <div className="text-zinc-800 text-[11px] font-medium ml-[3px] mt-[6px] leading-[18px]">
                    火
                  </div>
                  <input
                    defaultValue="1"
                    name="is_regular_day_off_on_wednesday"
                    {...register("is_regular_day_off_on_wednesday")}
                    type="checkbox"
                    className="w-3.5 h-3.5 bg-blue-900 rounded-sm ml-[13px] mt-[8px]"
                  />
                  <div className="text-zinc-800 text-[11px] font-medium ml-[3px] mt-[6px] leading-[18px]">
                    水
                  </div>
                  <input
                    defaultValue="1"
                    name="is_regular_day_off_on_thirsday"
                    {...register("is_regular_day_off_on_thirsday")}
                    type="checkbox"
                    className="w-3.5 h-3.5 bg-blue-900 rounded-sm ml-[13px] mt-[8px]"
                  />
                  <div className="text-zinc-800 text-[11px] font-medium ml-[3px] mt-[6px] leading-[18px]">
                    木
                  </div>
                  <input
                    defaultValue="1"
                    name="is_regular_day_off_on_friday"
                    {...register("is_regular_day_off_on_friday")}
                    type="checkbox"
                    className="w-3.5 h-3.5 bg-blue-900 rounded-sm ml-[13px] mt-[8px]"
                  />
                  <div className="text-zinc-800 text-[11px] font-medium ml-[3px] mt-[6px] leading-[18px]">
                    金
                  </div>
                  <input
                    defaultValue="1"
                    name="is_regular_day_off_on_saturday"
                    {...register("is_regular_day_off_on_saturday")}
                    type="checkbox"
                    className="w-3.5 h-3.5 bg-blue-900 rounded-sm ml-[13px] mt-[8px]"
                  />
                  <div className="text-zinc-800 text-[11px] font-medium ml-[3px] mt-[6px] leading-[18px]">
                    土
                  </div>
                  <input
                    defaultValue="1"
                    name="is_regular_day_off_on_sunday"
                    {...register("is_regular_day_off_on_sunday")}
                    type="checkbox"
                    className="w-3.5 h-3.5 bg-blue-900 rounded-sm ml-[13px] mt-[8px]"
                  />
                  <div className="text-zinc-800 text-[11px] font-medium ml-[3px] mt-[6px] leading-[18px]">
                    日
                  </div>
                  <input
                    defaultValue="1"
                    name="is_regular_day_off_on_holiday"
                    {...register("is_regular_day_off_on_holiday")}
                    type="checkbox"
                    className="w-3.5 h-3.5 bg-blue-900 rounded-sm ml-[13px] mt-[8px]"
                  />
                  <div className="text-zinc-800 text-[11px] font-medium ml-[3px] mt-[6px] leading-[18px]">
                    祝
                  </div>
                </div>
                <div className="flex flex-row ml-[36px] items-center">
                  <div className="flex w-[140px] ">定休日備考</div>
                  <input
                    name="regular_day_off_note"
                    {...register("regular_day_off_note")}
                    className="p-2 mt-[9px] w-[666px] h-[35px] bg-white rounded-[10px] border border-stone-300 placeholder:font-normal"
                    placeholder="土曜は隔週"
                    onFocus={(e) => (e.target.placeholder = "")}
                    onBlur={(e) => (e.target.placeholder = "土曜は隔週")}
                  />
                </div>
                <div className="w-[825px] h-px bg-zinc-300 ml-[28px] mt-[8px] border-b border-gray-200" />
                {catLevel &&
                  catLevel.map((catItem, index) => {
                    return (
                      <Fragment key={catItem.level1 + "_" + index}>
                        <div className="flex flex-row ml-[36px] mt-[8px] items-center">
                          <div>{index == 0 ? "取扱品目" : ""}</div>
                          <select
                            id="bigCategory"
                            defaultValue={catItem.level1}
                            onChange={(e) => handleChangeCat(e, index, 1)}
                            className={`${
                              index == 0 ? " ml-[83px]" : " ml-[140px]"
                            } w-40 h-8 bg-white rounded-[10px] border border-stone-300 focus:outline-none placeholder:font-normal`}
                          >
                            {categoryLevel1.map((item, i) => {
                              return (
                                <option
                                  className=""
                                  value={item.value}
                                  key={item.value + "_" + i}
                                >
                                  {item.label}
                                </option>
                              );
                            })}
                          </select>
                          <select
                            defaultValue={catItem.level2}
                            onChange={(e) => handleChangeCat(e, index, 2)}
                            className="w-40 h-8 ml-[13px] bg-white rounded-[10px] border border-stone-300 invalid:text-gray-400 placeholder:font-normal"
                          >
                            {categoryLevel2.map((item, i) => {
                              if (
                                item.level1 == catItem.level1 ||
                                item.value == ""
                              ) {
                                return (
                                  <option
                                    value={item.value}
                                    key={item.value + "_" + i}
                                  >
                                    {item.label}
                                  </option>
                                );
                              }
                            })}
                          </select>
                          <select
                            id="thirdCategory"
                            defaultValue={catItem.level3}
                            onChange={(e) => handleChangeCat(e, index, 3)}
                            className="w-40 h-8 ml-[13px] bg-white rounded-[10px] border border-stone-300 invalid:text-gray-400 placeholder:font-normal"
                          >
                            {categoryLevel3.map((item, i) => {
                              if (
                                (item.level1 == catItem.level1 &&
                                  item.level2 == catItem.level2) ||
                                item.value == ""
                              ) {
                                return (
                                  <option
                                    value={item.value}
                                    key={item.value + "_" + i}
                                  >
                                    {item.label}
                                  </option>
                                );
                              }
                            })}
                          </select>
                          {index == 0 && (
                            <Fragment>
                              <img
                                onClick={() => handleAddCategory()}
                                className="w-[20px] h-[20px] ml-[13px] cursor-pointer"
                                src={plus}
                              />
                              <div
                                onClick={() => handleAddCategory()}
                                className="text-black text-xs font-normal ml-[4px]"
                              >
                                取扱品目を追加する
                              </div>
                            </Fragment>
                          )}
                          {index != 0 && (
                            <img
                              onClick={() => handledeleteCategory(index)}
                              className="w-[20px] h-[20px] ml-[13px] cursor-pointer"
                              src={minus}
                            ></img>
                          )}
                        </div>
                      </Fragment>
                    );
                  })}
                <div className="w-[825px] h-px bg-zinc-300 ml-[28px] mt-[8px] border-b border-gray-200" />
                <div className="flex flex-row ml-[36px] mt-[8px]">
                  <div className="w-[140px]">詳細</div>
                  <textarea
                    name="detail"
                    {...register("detail", {
                      maxLength: {
                        value: 1400,
                        message: "Invalid maxlength",
                      },
                    })}
                    className="p-2 w-[666px] h-[67px]bg-white rounded-[10px] border border-stone-300"
                    placeholder="1400字以内字以内&#10;営業時間は変動する可能性がございます"
                    onFocus={(e) => (e.target.placeholder = "")}
                    onBlur={(e) =>
                      (e.target.placeholder =
                        "1400字以内\n営業時間は変動する可能性がございます")
                    }
                  />
                </div>
                {errors.detail && (
                  <div className="ml-[185px] w-fit text-red-500 mb-[15px] h-px">
                    <span>{errors.detail.message}</span>
                  </div>
                )}
                <div className="w-[825px] h-px bg-zinc-300 ml-[28px] mt-[17px] border-b border-gray-200" />
                <div className="flex flex-row ml-[36px] mt-[8px]">
                  <div className="w-[140px]">備考</div>
                  <textarea
                    name="memo"
                    {...register("memo", {
                      maxLength: {
                        value: 1400,
                        message: "Invalid maxlength",
                      },
                    })}
                    className="p-2 w-[666px] h-[67px] bg-white rounded-[10px] border border-stone-300"
                    // placeholder="備考"
                    // onFocus={(e) => (e.target.placeholder = "")}
                    // onBlur={(e) => (e.target.placeholder = "備考")}
                  />
                </div>
                {errors.memo && (
                  <div className="ml-[185px] w-fit text-red-500 mb-[15px] h-px">
                    <span>{errors.memo.message}</span>
                  </div>
                )}
                <div className="w-[825px] h-px bg-zinc-300 ml-[28px] mt-[17px] border-b border-gray-200" />
                <div className="flex flex-row ml-[36px] mt-[17px]">
                  <div className="w-[140px]">アカウント登録日</div>
                  <div className="text-black text-sm font-normal ">
                    {current_date}
                  </div>
                </div>
                <div className="flex flex-row ml-[36px] mt-[20px]">
                  <div className="w-[140px]">アカウント登録者ID</div>
                  <div className="text-black font-normal ">
                    {/* <Tooltip title={userLogin.name} arrow>
                      <span>
                        {userLogin?.name?.length <= 20
                          ? userLogin.name
                          : userLogin?.name?.substring(0, 20) + "..."}
                      </span>
                    </Tooltip> */}
                    <Tooltip title={"100" + userLogin?.id} arrow>
                      <span>{"100" + userLogin?.id}</span>
                    </Tooltip>
                  </div>
                </div>
                <div className="flex flex-row ml-[235px] mt-[40px]">
                  <button
                    type="button"
                    className="w-[193px] h-[39px] bg-white bg-opacity-60 rounded-[50px] border border-black border-opacity-20 text-stone-500 text-xl font-bold hover:bg-gray-300 active:bg-gray-300 focus:outline-none focus:ring focus:ring-gray-300"
                    onClick={() => {
                      resetCatLevel();
                      onClose();
                      reset();
                      setRequiredValue([]);
                      // setIsAllTime(false);
                    }}
                  >
                    キャンセル
                  </button>
                  <button
                    disabled={isError || errorTime1 || errorTime2 || errorTime3}
                    type="button"
                    className={`${
                      isError || errorTime1 || errorTime2 || errorTime3
                        ? "bg-[#748bbe]"
                        : "bg-blue-900"
                    } w-[193px] h-[39px] ml-[50px] rounded-[50px] text-white text-xl font-bold `}
                    onClick={handleSubmit(handleFormSubmit)}
                  >
                    新規登録
                  </button>
                </div>
              </div>
            </ClickAwayListener>
          </div>
          <div className="flex flex-row ml-[36px] mt-[20px]">
            <div>アカウント登録者ID</div>
            <div className=" ml-[10px] text-black text-sm font-normal ">
              {/* <Tooltip title={userLogin.name} arrow>
                <span>
                  {userLogin?.name?.length <= 20
                    ? userLogin.name
                    : userLogin?.name?.substring(0, 20) + "..."}
                </span>
              </Tooltip> */}
              <Tooltip title={"100" + userLogin?.id} arrow>
                <span className="text-black text-sm font-normal ml-[17px]">
                  {"100" + userLogin?.id}
                </span>
              </Tooltip>
            </div>
          </div>
        </form>
      </Dialog>
    </div>
  );
}
