import React from "react";
import polygon2 from "../../../assets/polygon2.png";
import polygon1 from "../../../assets/polygon1.png";
import UserDetail from "./UserDetail";
import DialogCompleted from "../../../components/DialogCompleted";
import DeleteUserConfirm from "./DeleteUserConfirm";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../../actions";
import ReactPaginate from "react-paginate";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import Moment from "moment";
import { Tooltip } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import $ from "jquery";

export default function TabUserUnApprove({ openModal }) {
  const [userId, setUserId] = React.useState();
  const [userIds, setUserIds] = React.useState([]);
  const [modalDetailOpen, setModalDetailOpen] = React.useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = React.useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [currentLimit, setCurrentLimit] = React.useState(10);
  const [searchValue, setSearchValue] = React.useState("");
  const [messageSuccess, setMessageSuccess] = React.useState("");
  const [filter, setFilter] = React.useState({
    name: 1,
    company_working_at_name: 1,
    company_working_at_phone_number: 1,
    email: 1,
    gender: 1,
    age: 1,
    created_at: 1,
  });
  const [body, setBody] = React.useState({
    number_of_records_per_page: 10,
    page_number: 1,
    orderBy: "name",
    direction: "DESC",
  });

  const dispatch = useDispatch();
  const users = useSelector((state) => state.user.unapproves);

  const gender = ["男性", "女性", "その他", "回答しない"];

  const getUsser = async () => {
    await dispatch(userActions.getUnApproveUsers(searchValue, body));
  };

  React.useEffect(() => {
    getUsser();
  }, []);

  React.useEffect(() => {}, [users.items]);

  React.useEffect(() => {
    dispatch(userActions.getUnApproveUsers(searchValue, body));
  }, [body, searchValue]);

  const handleCloseDialog = () => {
    setModalRegistOpen(false);
    openModal(false);
  };

  const handleDeleteDialogOpen = () => {
    if (userIds.length > 0) {
      setConfirmDeleteOpen(true);
      openModal(true);
    }
  };

  const handleDelete = async () => {
    if (userId) {
      var data = {
        ids: [userId],
      };
    } else if (userIds.length > 0) {
      var data = {
        ids: userIds,
      };
    }

    await dispatch(userActions.delete(data));
    setConfirmDeleteOpen(false);
    setSuccessDialogOpen(true);
    setMessageSuccess(data.ids.length + "件のユーザー削除が\n完了しました");
    openModal(false);
    setUserId();
    setTimeout(() => {
      setUserIds([]);
    }, 3000);
    setSearchValue("");
    getUsser();
  };

  const handleChangeValue = (e) => {
    if (e.target.checked) {
      setUserIds([...userIds, e.target.value]);
    } else {
      var array = [...userIds];
      var index = array.indexOf(e.target.value);
      if (index !== -1) {
        array.splice(index, 1);
        setUserIds(array);
      }
    }
  };

  const handleUpdate = (message) => {
    setSearchValue("");
    setModalDetailOpen(false);
    setMessageSuccess(message);
    setSuccessDialogOpen(true);
    openModal(true);
    getUsser();
  };

  const handleOpenDetail = (id) => {
    setUserId(id);
    setModalDetailOpen(true);
    openModal(true);
  };

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
    setBody((body) => ({
      ...body,
      number_of_records_per_page: currentLimit,
      page_number: event.selected + 1,
    }));
  };

  const handleSearch = (value) => {
    dispatch(userActions.getUnApproveUsers(value, body));
  };

  const onChangeSearchValue = (event) => {
    setSearchValue(event.target.value);
  };

  const onChangeLimit = (event) => {
    setCurrentLimit(event.target.value);
    setCurrentPage(0);
    setBody((body) => ({
      ...body,
      number_of_records_per_page: event.target.value,
      page_number: 1,
    }));
  };

  const optionLimitPage = [
    { value: "10", label: "10" },
    { value: "50", label: "50" },
    { value: "100", label: "100" },
    { value: "1000", label: "1000" },
  ];

  const handleFilter = (field) => {
    let direction;
    if (field == "name") {
      filter.name == 0 ? (filter.name = 1) : (filter.name = 0);
      filter.name == 0 ? (direction = "ASC") : (direction = "DESC");
    } else if (field == "company_working_at_name") {
      filter.company_working_at_name == 0
        ? (filter.company_working_at_name = 1)
        : (filter.company_working_at_name = 0);
      filter.company_working_at_name == 0
        ? (direction = "ASC")
        : (direction = "DESC");
    } else if (field == "company_working_at_phone_number") {
      filter.company_working_at_phone_number == 0
        ? (filter.company_working_at_phone_number = 1)
        : (filter.company_working_at_phone_number = 0);
      filter.company_working_at_phone_number == 0
        ? (direction = "ASC")
        : (direction = "DESC");
    } else if (field == "email") {
      filter.email == 0 ? (filter.email = 1) : (filter.email = 0);
      filter.email == 0 ? (direction = "ASC") : (direction = "DESC");
    } else if (field == "gender") {
      filter.gender == 0 ? (filter.gender = 1) : (filter.gender = 0);
      filter.gender == 0 ? (direction = "ASC") : (direction = "DESC");
    } else if (field == "age") {
      filter.age == 0 ? (filter.age = 1) : (filter.age = 0);
      filter.age == 0 ? (direction = "ASC") : (direction = "DESC");
    } else if (field == "created_at") {
      filter.created_at == 0
        ? (filter.created_at = 1)
        : (filter.created_at = 0);
      filter.created_at == 0 ? (direction = "ASC") : (direction = "DESC");
    }
    setFilter(filter);
    setBody((body) => ({
      ...body,
      orderBy: field,
      direction: direction,
    }));
  };

  React.useEffect(() => {}, [searchValue]);

  $(function () {
    $("select").each(function () {
      this.style.setProperty("color", "#000", "important");
    });
    $("option").each(function () {
      this.style.setProperty("color", "#000", "important");
    });
  });

  return (
    <>
      <div className="relative flex flex-row xl:w-full lg:w-full 2xl:w-full h-[75px] bg-zinc-100 justify-between">
        <button
          disabled={userIds.length <= 0}
          onClick={() => handleDeleteDialogOpen(true)}
          className={`${
            userIds.length > 0
              ? "border-blue-900 text-blue-900"
              : "border-gray-300 text-gray-300"
          } w-[81px] h-6 mt-[29px] ml-[9px] bg-white rounded-[50px] border text-center text-xs font-bold hover:bg-violet-100 active:bg-violet-100 focus:outline-none focus:ring focus:ring-violet-300`}
        >
          一括削除
        </button>
        <div className="relative flex flex-col 2xl:w-[50%] xl:w-[50%] lg:w-[50%] h-[51px] mt-[9px]">
          <span className="text-black text-sm font-normal">キーワード検索</span>
          <input
            placeholder="キーワードを入力…"
            className="p-2 2xl:w-full xl:w-full lg:w-full h-[27px] bg-white rounded-[5px] text-black/opacity-60 text-xs font-normal"
            value={searchValue}
            onChange={(e) => onChangeSearchValue(e)}
          />
          <button
            onClick={() => {
              handleSearch(searchValue);
            }}
            className="absolute right-[5px] top-[24px] w-[61px] h-[18px] bg-blue-900 rounded-[50px] border border-blue-900 text-center text-white text-xs font-bold hover:bg-violet-600 active:bg-violet-700 focus:outline-none focus:ring focus:ring-violet-300"
          >
            検索
          </button>
        </div>
      </div>

      <div className=" relative flex 2xl:w-full xl:w-full lg:w-full h-[35px] mt-[16px] bg-zinc-100 items-center lg:text-xs">
        <div className="flex 2xl:w-[7%] xl:w-[7%] lg:w-[7%] justify-center items-center">
          {/* <span className="">#</span>
          <img
            onClick={() => handleFilter(2)}
            className="w-[9px] h-[9px] 2xl:ml-[10px]"
            src={polygon}
          /> */}
          <div className="text-zinc-800 text-[11px] font-normal leading-3 2xl:pr-[20px] xl:pr-[10px] ml-[43px]">
            認証
          </div>
        </div>

        <div className="flex 2xl:w-[15%] xl:w-[15%] lg:w-[15%] justify-start items-center truncate xl:text-xs ml-[15px]">
          <span className="text-center text-black text-sm font-normal truncate ">
            ユーザー名
          </span>
          <img
            onClick={() => handleFilter("name")}
            className=" w-[9px] h-[9px] 2xl:ml-[20px]"
            src={filter.name == 0 ? polygon2 : polygon1}
          />
        </div>
        <div className="flex 2xl:w-[13.7%] xl:w-[13.7%] lg:w-[13.7%] justify-start items-center">
          <span className="text-center text-black text-sm font-normal">
            在籍企業名
          </span>
          <img
            onClick={() => handleFilter("company_working_at_name")}
            className="2xl:ml-[2px] xl:ml-2px w-[9px] h-[9px] "
            src={filter.company_working_at_name == 0 ? polygon2 : polygon1}
          />
        </div>
        <div className="flex 2xl:w-[12%] xl:w-[12%] lg:w-[12%] justify-start items-center">
          <span className=" text-center text-black text-sm font-normal">
            在籍企業電話番号
          </span>
          <img
            onClick={() => handleFilter("company_working_at_phone_number")}
            className="2xl:ml-[2px] xl:ml-2px w-[9px] h-[9px]"
            src={
              filter.company_working_at_phone_number == 0 ? polygon2 : polygon1
            }
          />
        </div>
        <div className="flex 2xl:w-[21%] xl:w-[21%] lg:w-[21%] justify-start items-center">
          <span className="text-center text-black text-sm font-normal">
            メールアドレス
          </span>
          <img
            onClick={() => handleFilter("email")}
            className="2xl:ml-[2px] xl:ml-2px w-[9px] h-[9px]"
            src={filter.email == 0 ? polygon2 : polygon1}
          />
        </div>
        <div className="flex 2xl:w-[10%] xl:w-[10%] lg:w-[10%] justify-start items-center ml-[-4px]">
          <span className="text-center text-black text-sm font-normal">
            属性
          </span>
          <img
            onClick={() => handleFilter("gender")}
            className="2xl:ml-[2px] xl:ml-2px w-[9px] h-[9px]"
            src={filter.gender == 0 ? polygon2 : polygon1}
          />
        </div>
        <div className="flex 2xl:w-[6.7%] xl:w-[6.7%] lg:w-[6.7%] justify-start items-center">
          <span className="text-center text-black text-sm font-normal">
            年齢
          </span>
          <img
            onClick={() => handleFilter("age")}
            className="2xl:ml-[2px] xl:ml-2px w-[9px] h-[9px]"
            src={filter.age == 0 ? polygon2 : polygon1}
          />
        </div>

        <div className="flex justify-start items-center">
          <span className="text-center text-black text-sm font-normal">
            申請日
          </span>
          <img
            onClick={() => handleFilter("created_at")}
            className="2xl:ml-[2px] xl:ml-2px w-[9px] h-[9px]"
            src={filter.created_at == 0 ? polygon2 : polygon1}
          />
        </div>
      </div>
      <div className="2xl:w-full xl:w-full lg:w-full max-h-[541px] h-fit overflow-y-scroll min-h-fit xl:h-[70%] lg:h-[70%] 2xl:h-[80%]">
        {users.items && users.items.data.users.length > 0 ? (
          users.items.data.users.map((item, i) => {
            var phone = "" + item.company_working_at_phone_number;
            var formated_phone =
              phone.substring(0, 3) +
              "-" +
              phone.substring(3, 7) +
              "-" +
              phone.substring(7, 11) +
              phone.substring(11, phone.length);
            // var formated_phone = item.company_working_at_phone_number?.replace(
            //   /(\d{2})(\d{4})(\d{4})/,
            //   "$1-$2-$3"
            // );
            return (
              <div
                key={item.id + "_" + i}
                className="relative flex 2xl:w-full xl:w-full lg:w-full 2xl:h-[72px] xl:h-[72px] lg:h-[72px] items-center border-b-[1px] border-zinc-300"
              >
                <div className="2xl:w-[3.6%] xl:w-[3.6%] lg:[3.6%] flex justify-center truncate ">
                  <input
                    className="w-3.5 h-3.5"
                    type="checkbox"
                    value={item.id}
                    onChange={(e) => handleChangeValue(e)}
                  />
                </div>

                <div
                  className="relative flex 2x:w-[96.4%] xl:w-[96.4%] lg:w-[96.4%] h-[72px] items-center cursor-pointer"
                  onClick={() => handleOpenDetail(item.id)}
                >
                  <div className="2xl:w-[3.7%] xl:w-[3.7%] lg:w-[6%] text-zinc-800 text-sm font-normal leading-3 2xl:flex 2xl:justify-start lg:flex lg:justify-center truncate">
                    <Tooltip title={"未承認"} arrow>
                      <span
                        className="text-red-500"
                        style={{ fontSize: "11px" }}
                      >
                        未承認
                      </span>
                    </Tooltip>
                  </div>
                  <div className="2xl:w-[16%] xl:w-[16%] lg:w-[16%] text-black text-sm font-normal truncate ml-[15px]">
                    <Tooltip title={item.name} arrow>
                      <span>
                        {/* {item.name.length <= 10
                          ? item.name
                          : item.name.substring(0, 10) + "..."} */}
                        {item.name}
                      </span>
                    </Tooltip>
                  </div>
                  <div className="2xl:w-[14.5%] xl:w-[14.5%] lg:w-[14.5%] text-black text-sm font-normal truncate ">
                    <Tooltip title={item.company_working_at_name} arrow>
                      <span>
                        {/* {item.company_working_at_name.length <= 10
                          ? item.company_working_at_name
                          : item.company_working_at_name.substring(0, 10) +
                            "..."} */}
                        {item.company_working_at_name}
                      </span>
                    </Tooltip>
                  </div>
                  <div className="2xl:w-[12%] xl:w-[12%] lg:w-[12%] text-black text-sm font-normal truncate ">
                    <Tooltip title={item.company_working_at_phone_number} arrow>
                      <span>
                        {/* {formated_phone.length <= 15
                          ? formated_phone
                          : formated_phone.substring(0, 15) + "..."} */}
                        {item.company_working_at_phone_number}
                      </span>
                    </Tooltip>
                  </div>
                  <div className="2xl:w-[22%] xl:w-[22%] lg:w-[22%] text-black text-sm font-normal truncate pr-[5px]">
                    <Tooltip title={item.email} arrow>
                      {/* <span>
                        {item.email.length <= 10
                          ? item.email
                          : item.email.substring(0, 10) + "..."}
                      </span> */}
                      <span>{item.email}</span>
                    </Tooltip>
                  </div>
                  <div className="2xl:w-[10.5%] xl:w-[10.5%] lg:w-[10.5%] text-black text-sm font-normal truncate ">
                    {gender[item.gender - 1]}
                  </div>
                  <div className="2xl:w-[7.3%] xl:w-[7.3%] lg:w-[7.3%] text-black text-sm font-normal truncate ">
                    {item.age ? item.age + "0 - " + item.age + "9歳" : "未回答"}
                  </div>
                  <div className="2xl:w-[6.7%] xl:w-[6.7%] lg:w-[6.7%] text-black text-sm font-normal truncate">
                    <Tooltip
                      title={
                        item.created_at
                          ? Moment(item.created_at).format("YYYY/MM/DD")
                          : ""
                      }
                      arrow
                    >
                      <span>
                        {item.created_at
                          ? Moment(item.created_at).format("YYYY/MM/DD")
                          : ""}
                      </span>
                    </Tooltip>
                  </div>
                  <button
                    onClick={() => handleOpenDetail(item.id)}
                    href="#"
                    className="text-black text-sm font-normal flex text-blue-900 text-sm font-bold justify-end ml-[20px]"
                  >
                    詳細
                  </button>
                </div>
              </div>
            );
          })
        ) : (
          <>
            <div className="flex w-[80%] 2xl:w-full xl:w-full lg:w-full h-full justify-center items-center">
              {users.loading ? (
                <div className="text-black text-[15px] font-normal ">
                  <CircularProgress color="secondary" />
                </div>
              ) : (
                <div className=" text-black text-[15px] font-normal ml-[15px]">
                  一致する結果はありません
                </div>
              )}
            </div>
          </>
        )}
      </div>

      <DeleteUserConfirm
        open={confirmDeleteOpen}
        onClose={() => {
          setConfirmDeleteOpen(false);
          openModal(false);
          setUserId();
        }}
        onConfirm={() => handleDelete()}
        numberUser={userIds?.length ?? 1}
      />
      <DialogCompleted
        open={successDialogOpen}
        onClose={() => {
          setSuccessDialogOpen(false);
          openModal(false);
        }}
        message={messageSuccess}
      />
      <UserDetail
        open={modalDetailOpen}
        onClose={() => {
          setModalDetailOpen(false);
          openModal(false);
        }}
        onOpenConfirmDelete={() => {
          setModalDetailOpen(false);
          setConfirmDeleteOpen(true);
          openModal(true);
        }}
        onApprove={() => handleUpdate("1件のユーザーの承認が\n完了しました")}
        onReject={() => handleUpdate("1件のユーザーの非承認が\n完了しました")}
        id={userId}
      />
      {users.items && users.items.data && users.items.data.users.length > 0 && (
        <div className="relative flex justify-between mt-[17px] mr-[5px]">
          <div className="flex items-center">
            <div className="w-[38px] h-3 text-zinc-500 text-[11px] font-medium  leading-3">
              表示数
            </div>
            <select
              defaultValue={currentLimit}
              className="w-[69px] h-[23px] bg-white rounded-sm border border-stone-300 ml-[5px] mr-[5px] focus:ring-blue-500 focus:border-blue-500 text-center text-sm cursor-pointer"
              onChange={onChangeLimit}
            >
              {optionLimitPage.map((item, index) => {
                return (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                );
              })}
            </select>
            <div className="w-44 h-3 text-zinc-500 text-[11px] font-medium  leading-3">
              全 {users.items.data.totalRows} 件中 /{" "}
              {currentPage * currentLimit + 1}-
              {(currentPage + 1) * currentLimit > users.items.data.totalRows &&
              (users.items.data.totalPages == 1 ||
                currentPage + 1 == users.items.data.totalPages)
                ? users.items.data.totalRows
                : (currentPage + 1) * currentLimit}
              件目を表示
            </div>
          </div>

          <ReactPaginate
            breakClassName="pb-[8px] mr-[5px]"
            containerClassName=" flex items-center justify-center"
            previousLabel={
              <span className="w-3 h-3 flex items-center justify-center rounded-md">
                <BsArrowLeft />
              </span>
            }
            nextLabel={
              <span className="w-3 h-3 flex items-center justify-center rounded-md">
                <BsArrowRight />
              </span>
            }
            pageClassName="block border-solid hover:rounded-full hover:bg-gray-100 w-5 h-5 text-center text-zinc-800 text-[12px] font-bold  leading-3 flex items-center justify-center rounded-md mx-[3px]"
            onPageChange={handlePageClick}
            pageRangeDisplayed={10}
            marginPagesDisplayed={0}
            activeClassName="block border-solid rounded-md rounded-full bg-gray-100 w-5 h-5"
            pageCount={users.items.data.totalPages}
            breakLabel="..."
            renderOnZeroPageCount={null}
            forcePage={currentPage}
            pageLinkClassName="text-[12px]"
          ></ReactPaginate>
        </div>
      )}
    </>
  );
}
